var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "platform section position-relative"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "position-absolute",
    staticStyle: {
      "right": "0",
      "left": "0",
      "margin-right": "0",
      "margin-left": "0"
    }
  }, [_c('div', {
    staticClass: "d-none d-xl-block text-center"
  }, [_c('Background')], 1), _c('div', {
    staticClass: "d-none d-md-block d-xl-none text-center"
  }, [_c('TabletBackground')], 1)]), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-12 p-0"
  }, [_c('h2', {
    staticClass: "text-center light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("trading platform.choose")) + " ")]), _c('div', {
    staticClass: "platform-items"
  }, [_c('div', {
    staticClass: "platform-item d-flex flex-row justify-content-center"
  }, [_c('div', {
    staticClass: "d-md-none"
  }, [_c('CheckRoundedIcon')], 1), _c('div', [_c('h3', [_vm._v(_vm._s(_vm.$t("trading platform.analysis")))]), _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "gap": "12px"
    }
  }, [_c('div', {
    staticClass: "d-none d-md-block",
    staticStyle: {
      "width": "25px",
      "height": "25px"
    }
  }, [_c('CheckRoundedIcon')], 1), _c('p', {
    staticClass: "platform-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("trading platform.analysis-text")) + " ")])])])]), _c('div', {
    staticClass: "platform-item d-flex flex-row justify-content-center"
  }, [_c('div', {
    staticClass: "d-md-none"
  }, [_c('CheckRoundedIcon')], 1), _c('div', [_c('h3', [_vm._v(_vm._s(_vm.$t("trading platform.calendar")))]), _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "gap": "12px"
    }
  }, [_c('div', {
    staticClass: "d-none d-md-block",
    staticStyle: {
      "width": "25px",
      "height": "25px"
    }
  }, [_c('CheckRoundedIcon')], 1), _c('p', {
    staticClass: "platform-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("trading platform.caledar-text")) + " ")])])])]), _c('div', {
    staticClass: "platform-item flex flex-row justify-content-center"
  }, [_c('div', {
    staticClass: "d-md-none"
  }, [_c('CheckRoundedIcon')], 1), _c('div', [_c('h3', [_vm._v(_vm._s(_vm.$t("trading platform.crypto")))]), _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "gap": "12px"
    }
  }, [_c('div', {
    staticClass: "d-none d-md-block",
    staticStyle: {
      "width": "25px",
      "height": "25px"
    }
  }, [_c('CheckRoundedIcon')], 1), _c('p', {
    staticClass: "platform-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("trading platform.crypto-text")) + " ")])])])]), _c('div', {
    staticClass: "platform-item flex flex-row justify-content-center"
  }, [_c('div', {
    staticClass: "d-md-none"
  }, [_c('CheckRoundedIcon')], 1), _c('div', [_c('h3', [_vm._v(_vm._s(_vm.$t("trading platform.trading")))]), _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "gap": "12px"
    }
  }, [_c('div', {
    staticClass: "d-none d-md-block",
    staticStyle: {
      "width": "25px",
      "height": "25px"
    }
  }, [_c('CheckRoundedIcon')], 1), _c('p', {
    staticClass: "platform-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("trading platform.trading-text")) + " ")])])])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }