var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "1012",
      "height": "581",
      "viewBox": "0 0 1012 581",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "521.5",
      "cy": "567.5",
      "r": "4.5",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M986.137 380.713L978.712 388.138",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M978.715 380.713L986.139 388.138",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.1367 312.713L3.7121 320.138",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M3.71484 312.713L11.1395 320.138",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "1006.5",
      "cy": "225.5",
      "r": "4.5",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('circle', {
    attrs: {
      "cx": "510",
      "cy": "355",
      "r": "9",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }