var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "56",
      "height": "56",
      "rx": "28",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M19.7033 20.7449C18.7243 20.8917 18 21.7328 18 22.7227V26.5006C18 26.7767 18.2239 27.0006 18.5 27.0006H25.5C25.7761 27.0006 26 26.7767 26 26.5006V20.381C26 20.0753 25.7281 19.8411 25.4258 19.8865L19.7033 20.7449Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M28.4258 19.4364C28.1811 19.4731 28 19.6834 28 19.9309V26.5005C28 26.7766 28.2239 27.0005 28.5 27.0005H37.5C37.7761 27.0005 38 26.7766 38 26.5005V20.3227C38 19.0999 36.9125 18.1634 35.7033 18.3448L28.4258 19.4364Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M38 29.5C38 29.2239 37.7761 29 37.5 29H28.5C28.2239 29 28 29.2239 28 29.5V36.0692C28 36.3167 28.1811 36.527 28.4258 36.5637L35.7033 37.6553C36.9125 37.8367 38 36.9002 38 35.6774V29.5Z",
      "fill": "#FCFCFD"
    }
  }), _c('path', {
    attrs: {
      "d": "M25.4258 36.1137C25.7281 36.159 26 35.9249 26 35.6192V29.5C26 29.2239 25.7761 29 25.5 29H18.5C18.2239 29 18 29.2239 18 29.5V33.2774C18 34.2674 18.7243 35.1085 19.7033 35.2553L25.4258 36.1137Z",
      "fill": "#FCFCFD"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }