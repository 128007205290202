<template>
  <div
  v-if="isCompanyFetched"
    id="links"
    class="download section d-flex flex-column flex-md-row-reverse justify-content-center"
    style="gap: 43px"
  >
    <div class="download-images">
      <div class="d-md-none d-xl-none text-center">
        <MobileDownloadIcon />
      </div>
      <div class="d-none d-md-block">
        <PCDownloadIcon id="pc" />
      </div>
    </div>
    <div class="download-content">
      <h2 class="light-theme-title">
        {{ $t("trading platform.download.title") }}
      </h2>
      <h4
        class="subtitle-bodyLarge-to-mobile-body-regular text-grey-primary-main-500"
        style="max-width: 460px"
      >
        {{ $t("trading platform.download.subtitle") }}
      </h4>
      <div class="download-options">
        <div class="download-option d-flex" style="gap: 24px">
          <a
            :href="companySettings?.platform_links.filter(p => p.platform_name.toLowerCase() == 'iOS'.toLowerCase())[0].platform_link"
            target="_blank"
          >
            <AppStore style="width: 56px; height: 56px" class="d-icons" />
          </a>
          <div class="download-text">
            <span class="small-text neutrals-4-text">{{
              $t("trading platform.download.download from")
            }}</span>
            <h4 class="subtitle-bodyLarge text-nowrap neutrals-2-text">
              <a
                :href="companySettings?.platform_links.filter(p => p.platform_name.toLowerCase() == 'iOS'.toLowerCase())[0].platform_link"
                target="_blank"
              >
                AppStore
              </a>
            </h4>
          </div>
        </div>
        <hr />
        <div class="download-option d-flex" style="gap: 24px">
          <a
            :href="companySettings?.platform_links.filter(p => p.platform_name.toLowerCase() == 'macOS'.toLowerCase())[0].platform_link"
            target="_blank"
          >
            <AppleIcon class="d-icons" />
          </a>
          <div class="download-text">
            <span class="small-text neutrals-4-text">{{
              $t("trading platform.download.download for")
            }}</span>
            <h4 class="subtitle-bodyLarge text-nowrap neutrals-2-text">
              <a
                :href="companySettings?.platform_links.filter(p => p.platform_name.toLowerCase() == 'macOS'.toLowerCase())[0].platform_link"
                target="_blank"
              >
                Mac OS
              </a>
            </h4>
          </div>
        </div>
        <hr />

        <div class="download-option d-flex" style="gap: 24px">
          <a
            :href="companySettings?.platform_links.filter(p => p.platform_name.toLowerCase() == 'Android'.toLowerCase())[0].platform_link"
            target="_blank"
          >
            <WindowIcon class="d-icons"
          /></a>
          <div class="download-text">
            <span class="small-text neutrals-4-text">{{
              $t("trading platform.download.download from")
            }}</span>
            <h4 class="subtitle-bodyLarge text-nowrap neutrals-2-text">
              <a
                :href="companySettings?.platform_links.filter(p => p.platform_name.toLowerCase() == 'Android'.toLowerCase())[0].platform_link"
                target="_blank"
              >
                Google Play
              </a>
            </h4>
          </div>
        </div>
        <hr />

        <div class="download-option d-flex" style="gap: 24px">
          <a
            :href="companySettings?.platform_links.filter(p => p.platform_name.toLowerCase() == 'Windows'.toLowerCase())[0].platform_link"
            target="_blank"
          >
            <GooglePlayIcon class="d-icons" />
          </a>
          <div class="download-text">
            <span class="small-text neutrals-4-text">{{
              $t("trading platform.download.download for")
            }}</span>
            <h4 class="subtitle-bodyLarge text-nowrap neutrals-2-text">
              <a
                :href="companySettings?.platform_links.filter(p => p.platform_name.toLowerCase() == 'Windows'.toLowerCase())[0].platform_link"
                target="_blank"
              >
                Windows
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import AppleIcon from "../svg/download/AppleIcon.vue";
import GooglePlayIcon from "../svg/download/GooglePlayIcon.vue";
import WindowIcon from "../svg/download/WindowIcon.vue";
import MobileDownloadIcon from "../svg/download/MobileDownloadIcon.vue";
import PCDownloadIcon from "../svg/download/PCDownloadIcon.vue";
import AppStore from "../svg/download/AppStore.vue";
export default {
  components: {
    AppleIcon,
    GooglePlayIcon,
    WindowIcon,
    MobileDownloadIcon,
    PCDownloadIcon,
    AppStore,
    },
    computed: {
        ...mapGetters({
            companySettings: "companySettings",
            isCompanyFetched: "isCompanyFetched"
        })
  }

};
</script>
<style lang="scss" scoped>
.download {
  .download-images {
    display: grid;
    grid-template-areas: "img";
    .download-image {
      grid-area: img;
    }
  }
}
#pc {
  @media (min-width: 768px) and (max-width: 1199px) {
    max-width: 443px;
    max-height: 552px;
  }
  @media (min-width: 1200px) {
    max-width: 473px;
    max-height: 589px;
  }
}
.d-icons {
  @media (max-width: 767px) {
    width: 50.3px;
    height: 50.3px;
  }
}

h2 {
  @media (max-width: 767px) {
    margin-bottom: 16px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-bottom: 24px !important;
  }
  @media (min-width: 1200px) {
    margin-bottom: 24px !important;
  }
}
.download-options {
  @media (max-width: 767px) {
    margin-bottom: 47px;
    margin-top: 45px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-bottom: 73px;
    margin-top: 48px;
  }
  @media (min-width: 1200px) {
    margin-bottom: 187px;
    margin-top: 64px;
  }
}
hr {
  @media (max-width: 767px) {
    margin-bottom: 16px;
    margin-top: 14px;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-bottom: 32px;
    margin-top: 16px;
  }
  @media (min-width: 1200px) {
    margin-bottom: 16px;
    margin-top: 32px;
  }
}

.subtitle-bodyLarge-to-mobile-body-regular {
  @media (min-width: 768px) {
    font-family: "Open Sans" !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 150% !important;
  }
}
a:hover {
  text-decoration: none;
  color: var(--primary) !important
}
</style>
