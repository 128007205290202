<template>
  <div class="hero-custom">
    <div class="hero-custom__content mx-auto">
      <h1 class="hero-custom__title">
        <span class="text-primary">MT5 </span>
        {{ $t("trading platform.hero.header") }}
      </h1>
      <h2 class="hero-custom__text hero-subtitle text-grey-primary-main-500">
        {{ $t("trading platform.hero.sub-header") }}
      </h2>

      <div class="hero-buttons d-flex">
        <b-button
          variant="primary"
          class="round-btn-16-24"
          style="width: 160px"
          @click="gotoDownload()"
        >
          <p class="button-medium text-white-button">
            {{ $t("trading platform.hero.download") }}
          </p>
        </b-button>
      </div>
    </div>

    <div class="d-none d-xl-block">
      <HomeHero />
    </div>
    <div class="d-none d-md-block d-xl-none">
      <HomeTabletHero />
    </div>

    <div class="d-block d-md-none">
      <HomeMobileHero />
    </div>
  </div>
</template>

<script>
import HomeHero from "../svg/hero/HomeHero.vue";
import HomeTabletHero from "../svg/hero/HomeTabletHero.vue";
import HomeMobileHero from "../svg/hero/HomeMobileHero.vue";

export default {
  components: {
    HomeHero,
    HomeTabletHero,
    HomeMobileHero,
  },
  props: ["gotoDownload"],
};
</script>

<style lang="scss" scoped>
.hero-content {
  @media (max-width: 1199px) and (min-width: 768px) {
    margin-left: 25px !important;
  }
}
h1 {
  @media (max-width: 767px) {
    margin-top: 37px !important;
    max-width: 305px !important;
  }
  @media (max-width: 991px) and (min-width: 768px) {
    margin-top: 0 !important;
  }
  @media (max-width: 1199px) and (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
}
h4 {
  @media (max-width: 767px) {
    max-width: 305px !important;
  }

  @media (max-width: 991px) and (min-width: 768px) {
    max-width: 400px !important;
  }
  @media (min-width: 1200px) {
  }
}
.round-btn-16-24 {
  @media (max-width: 767px) {
    margin-top: 23px;
    margin-bottom: 17px;
    width: 200px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    width: 200px !important;
  }
  @media (min-width: 1200px) {
    width: 200px !important;
    margin-top: 16px !important;
  }
}
.hero-custom__text {
  width: 562px;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 450px;
  }
  @media (max-width: 991px) {
    width: 304px;
  }
}
.hero-custom__title {
  @media (max-width: 767px) {
    margin-top: 0 !important;
  }
}
</style>
