var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "313",
      "height": "309",
      "viewBox": "0 0 313 309",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    staticStyle: {
      "mix-blend-mode": "multiply"
    }
  }, [_c('rect', {
    attrs: {
      "x": "226.09",
      "y": "3.36914",
      "width": "46.9344",
      "height": "70.7721",
      "fill": "#07B53B"
    }
  })]), _c('rect', {
    attrs: {
      "x": "229.852",
      "y": "1",
      "width": "44.9344",
      "height": "68.7721",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "2"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "252.594",
      "cy": "59.3563",
      "rx": "3.31302",
      "ry": "3.36409",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M254.899 100.472C254.899 103.031 252.86 105.079 250.377 105.079C247.894 105.079 245.855 103.031 245.855 100.472C245.855 97.9133 247.894 95.8652 250.377 95.8652C252.86 95.8652 254.899 97.9133 254.899 100.472Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M272.559 131.213L268.459 135.375",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M268.465 131.213L272.564 135.375",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M214.305 87H277.804",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M218.402 79H247.808",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M241.828 8.42578H261.43",
      "stroke": "#ED6400",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M178.7 87.577C178.7 88.8971 177.65 89.9411 176.387 89.9411C175.124 89.9411 174.074 88.8971 174.074 87.577C174.074 86.2569 175.124 85.2129 176.387 85.2129C177.65 85.2129 178.7 86.2569 178.7 87.577Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M254.341 241.302C254.341 242.622 253.291 243.666 252.028 243.666C250.765 243.666 249.715 242.622 249.715 241.302C249.715 239.981 250.765 238.938 252.028 238.938C253.291 238.938 254.341 239.981 254.341 241.302Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M122.058 80.3084C122.058 81.4737 121.132 82.3922 120.021 82.3922C118.911 82.3922 117.984 81.4737 117.984 80.3084C117.984 79.1431 118.911 78.2246 120.021 78.2246C121.132 78.2246 122.058 79.1431 122.058 80.3084Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M140.598 137.476C140.598 140.035 138.559 142.083 136.076 142.083C133.593 142.083 131.555 140.035 131.555 137.476C131.555 134.917 133.593 132.869 136.076 132.869C138.559 132.869 140.598 134.917 140.598 137.476Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M151.641 84.2969L147.542 88.4592",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M147.547 84.2969L151.646 88.4592",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M272.559 272.959L268.459 277.121",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M268.465 272.959L272.564 277.121",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M300.374 172.787C300.374 184.283 291.217 193.573 279.957 193.573C268.696 193.573 259.539 184.283 259.539 172.787C259.539 161.29 268.696 152 279.957 152C291.217 152 300.374 161.29 300.374 172.787Z",
      "stroke": "#ED6400",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M293.867 189.037L303.52 198.856",
      "stroke": "#ED6400",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M301.34 196.639L310.992 206.457",
      "stroke": "#ED6400",
      "stroke-width": "4",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M271.035 172.108L277.156 178.324L289.399 165.893",
      "stroke": "#07B53B",
      "stroke-width": "1.68505",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "46.0742",
      "y": "167.568",
      "width": "177.246",
      "height": "108.405",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "38.3438",
      "y": "275.973",
      "width": "192.707",
      "height": "15.7271",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "98.2683",
      "y": "276.815",
      "width": "68.4405",
      "height": "1.68505",
      "stroke": "#07B53B",
      "stroke-width": "1.68505"
    }
  }), _c('path', {
    attrs: {
      "d": "M83.9316 121.655H13.2539C13.2539 112.456 19.2609 104.633 27.6252 101.77C29.7162 92.3025 38.2934 85.2109 48.5585 85.2109C58.8085 85.2109 67.378 92.2876 69.4767 101.741C77.8865 104.58 83.9316 112.427 83.9316 121.655Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M54.1126 113.804H0C0 106.728 4.59914 100.709 11.003 98.5076C12.604 91.2246 19.1708 85.7695 27.0301 85.7695C34.8777 85.7695 41.4388 91.2131 43.0456 98.4846C49.4844 100.669 54.1126 106.705 54.1126 113.804Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M68.266 148.132C68.266 150.691 66.2272 152.739 63.7444 152.739C61.2615 152.739 59.2227 150.691 59.2227 148.132C59.2227 145.573 61.2615 143.525 63.7444 143.525C66.2272 143.525 68.266 145.573 68.266 148.132Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M24.6481 205.041C24.6481 206.206 23.7217 207.125 22.6112 207.125C21.5006 207.125 20.5742 206.206 20.5742 205.041C20.5742 203.875 21.5006 202.957 22.6112 202.957C23.7217 202.957 24.6481 203.875 24.6481 205.041Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M31.7188 307.5H113.992",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M34.4805 300.125H72.5802",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M83.0742 300.125L174.182 300.125",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "57.5664",
      "y": "164.635",
      "width": "175.246",
      "height": "106.405",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "2"
    }
  }), _c('rect', {
    attrs: {
      "x": "49.8359",
      "y": "271.354",
      "width": "190.707",
      "height": "13.7271",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "2"
    }
  }), _c('rect', {
    attrs: {
      "x": "95.918",
      "y": "270.477",
      "width": "94.1243",
      "height": "4.52344",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "91.8984",
      "y": "200.707",
      "width": "106.569",
      "height": "8.42525",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "91.8984",
      "y": "216.996",
      "width": "62.9473",
      "height": "8.42525",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "91.8984",
      "y": "233.285",
      "width": "46.3822",
      "height": "8.42525",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M169.726 103.257C107.055 100.167 67.7402 125.555 91.5939 148.472C121.411 177.118 173.315 144.821 191.261 173.748C209.206 202.674 224.667 279.906 258.349 255.473C292.032 231.04 205.46 81.5 242.324 46",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-dasharray": "5.62 5.62"
    }
  }), _c('path', {
    attrs: {
      "d": "M226.528 123.201H137.629C137.629 111.596 145.185 101.726 155.705 98.1151C158.335 86.1709 169.124 77.2246 182.036 77.2246C194.928 77.2246 205.707 86.1521 208.347 98.0774C218.925 101.66 226.528 111.558 226.528 123.201Z",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }