var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "636",
      "height": "365",
      "viewBox": "0 0 636 365",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M497.679 86.6319H349.297C349.297 67.5565 361.908 51.3323 379.468 45.3974C383.858 25.7642 401.865 11.0586 423.416 11.0586C444.935 11.0586 462.926 25.7332 467.332 45.3354C484.988 51.2239 497.679 67.4945 497.679 86.6319Z",
      "fill": "#ED6400"
    }
  }), _c('g', {
    staticStyle: {
      "mix-blend-mode": "multiply"
    }
  }, [_c('rect', {
    attrs: {
      "x": "543.762",
      "y": "23.0391",
      "width": "78.3382",
      "height": "116.125",
      "fill": "#07B53B"
    }
  })]), _c('rect', {
    attrs: {
      "x": "549.754",
      "y": "18.8922",
      "width": "75.5733",
      "height": "113.36",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "2.76488"
    }
  }), _c('circle', {
    attrs: {
      "cx": "587.998",
      "cy": "116.125",
      "r": "5.52975",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "488.462",
      "cy": "26.726",
      "r": "8.29463",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.84325"
    }
  }), _c('path', {
    attrs: {
      "d": "M519.004 103.879L512.161 110.722",
      "stroke": "#07B53B",
      "stroke-width": "1.84325",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M512.16 103.879L519.003 110.722",
      "stroke": "#07B53B",
      "stroke-width": "1.84325",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M529.016 150.225H635.003",
      "stroke": "#07B53B",
      "stroke-width": "1.84325",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M532.574 144.695H581.655",
      "stroke": "#07B53B",
      "stroke-width": "1.84325",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M570.023 31.334H602.741",
      "stroke": "#ED6400",
      "stroke-width": "2.76488"
    }
  }), _c('circle', {
    attrs: {
      "cx": "364.963",
      "cy": "5.52975",
      "r": "4.60813",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.84325"
    }
  }), _c('circle', {
    attrs: {
      "cx": "511.502",
      "cy": "268.194",
      "r": "4.60813",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.84325"
    }
  }), _c('circle', {
    attrs: {
      "cx": "289.85",
      "cy": "22.5807",
      "r": "4.14732",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.84325"
    }
  }), _c('circle', {
    attrs: {
      "cx": "297.685",
      "cy": "87.5541",
      "r": "8.29463",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.84325"
    }
  }), _c('path', {
    attrs: {
      "d": "M325.461 20.9316L318.618 27.7744",
      "stroke": "#07B53B",
      "stroke-width": "1.84325",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M318.617 20.9316L325.46 27.7744",
      "stroke": "#07B53B",
      "stroke-width": "1.84325",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M597.344 281.752L590.501 288.595",
      "stroke": "#07B53B",
      "stroke-width": "1.84325",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M590.5 281.752L597.343 288.595",
      "stroke": "#07B53B",
      "stroke-width": "1.84325",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "509.467",
      "cy": "207.172",
      "r": "34.8265",
      "stroke": "#ED6400",
      "stroke-width": "1.84325"
    }
  }), _c('path', {
    attrs: {
      "d": "M532.688 233.836L548.799 249.947",
      "stroke": "#ED6400",
      "stroke-width": "1.84325",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M545.164 246.309L561.275 262.42",
      "stroke": "#ED6400",
      "stroke-width": "9.21626",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M494.57 207.604L504.787 217.821L525.221 197.387",
      "stroke": "#07B53B",
      "stroke-width": "2.76488",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M401.827 52.0713C297.223 47.0022 231.603 88.6599 271.417 126.262C321.185 173.265 407.818 120.272 437.771 167.735C467.723 215.199 493.529 341.923 549.748 301.832C605.967 261.741 522.099 104.604 577.397 86.6323",
      "stroke": "#07B53B",
      "stroke-width": "4.60813",
      "stroke-linecap": "round",
      "stroke-dasharray": "9.22 9.22"
    }
  }), _c('rect', {
    attrs: {
      "x": "147.457",
      "y": "135.48",
      "width": "295.842",
      "height": "177.874",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "134.555",
      "y": "313.354",
      "width": "321.647",
      "height": "25.8055",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "233.629",
      "y": "313.814",
      "width": "116.125",
      "height": "4.60813",
      "stroke": "#07B53B",
      "stroke-width": "0.921626"
    }
  }), _c('path', {
    attrs: {
      "d": "M140.085 284.783H22.1172C22.1172 269.662 32.1435 256.801 46.1042 252.097C49.5944 236.534 63.9105 224.877 81.0441 224.877C98.1523 224.877 112.456 236.509 115.959 252.048C129.995 256.715 140.085 269.613 140.085 284.783Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M90.3193 271.88H0C0 260.249 7.67642 250.356 18.3651 246.737C21.0373 234.766 31.998 225.799 45.1159 225.799C58.2144 225.799 69.1654 234.747 71.8473 246.699C82.5943 250.29 90.3193 260.211 90.3193 271.88Z",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "176.951",
      "cy": "105.066",
      "r": "8.29463",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.84325"
    }
  }), _c('circle', {
    attrs: {
      "cx": "108.292",
      "cy": "198.61",
      "r": "4.14732",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.84325"
    }
  }), _c('path', {
    attrs: {
      "d": "M123.5 363.123H260.822",
      "stroke": "#07B53B",
      "stroke-width": "2.76488",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M128.109 352.984H191.702",
      "stroke": "#07B53B",
      "stroke-width": "2.76488",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M209.211 352.984L361.279 352.984",
      "stroke": "#07B53B",
      "stroke-width": "2.76488",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "166.355",
      "y": "130.412",
      "width": "293.077",
      "height": "175.109",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "2.76488"
    }
  }), _c('rect', {
    attrs: {
      "x": "153.453",
      "y": "305.521",
      "width": "318.882",
      "height": "23.0406",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "2.76488"
    }
  }), _c('rect', {
    attrs: {
      "x": "250.684",
      "y": "305.98",
      "width": "117.046",
      "height": "5.52975",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "223.957",
      "y": "189.855",
      "width": "177.874",
      "height": "13.8244",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "223.957",
      "y": "216.582",
      "width": "105.065",
      "height": "13.8244",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "223.957",
      "y": "243.311",
      "width": "77.4165",
      "height": "13.8244",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }