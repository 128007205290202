<script>
import Hero from "./components/sections/Hero.vue";
import WhyChooseMT5 from "./components/sections/WhyChooseMT5.vue";
import Download from "./components/sections/Download.vue";

export default {
  components: {
    Hero,
    WhyChooseMT5,
    Download,
  },
  methods: {
    gotoDownload() {
      window.scrollTo({
        top: this.$refs.download.$el.offsetTop,
        behavior: "smooth",
      });
    },
  },
  metaInfo: {
    title: 'NOZAX: MT5 in your pocket',
    meta: [
      { name: 'description', content: 'Get the complete trading experience with MT5, the best trading platform on the market. Download and trade on any device.' },
    ]
  }
};
</script>
<template>
  <div>
    <Hero :gotoDownload="gotoDownload" />
    <WhyChooseMT5 />
    <Download ref="download" />
  </div>
</template>

<style scoped></style>
