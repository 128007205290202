var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "59",
      "height": "59",
      "viewBox": "0 0 59 59",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "59",
      "height": "59",
      "rx": "29.5",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M33.5532 28.3581L20.6399 16.6857L37.0697 25.1881L33.5532 28.3581ZM17.2697 16C16.509 16.3587 16 17.0127 16 17.8619V41.1381C16 41.9873 16.509 42.6413 17.2697 43L32.2835 29.4974L17.2697 16ZM42.1485 27.8992L38.7022 26.1006L34.858 29.5026L38.7022 32.9047L42.2187 31.1061C43.2719 30.3518 43.2719 28.6534 42.1485 27.8992ZM20.6399 42.3196L37.0697 33.8172L33.5532 30.6472L20.6399 42.3196Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }