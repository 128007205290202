var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Hero', {
    attrs: {
      "gotoDownload": _vm.gotoDownload
    }
  }), _c('WhyChooseMT5'), _c('Download', {
    ref: "download"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }