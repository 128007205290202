<template>
  <div>
    <div class="platform section position-relative">
      <div class="container">
        <div
          class="position-absolute"
          style="right: 0; left: 0; margin-right: 0; margin-left: 0"
        >
          <div class="d-none d-xl-block text-center">
            <Background />
          </div>
          <div class="d-none d-md-block d-xl-none text-center">
            <TabletBackground />
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-12 p-0">
            <h2 class="text-center light-theme-title">
              {{ $t("trading platform.choose") }}
            </h2>

            <div class="platform-items">
              <div class="platform-item d-flex flex-row justify-content-center">
                <div class="d-md-none">
                  <CheckRoundedIcon />
                </div>
                <div>
                  <h3>{{ $t("trading platform.analysis") }}</h3>
                  <div class="d-flex" style="gap: 12px">
                    <div
                      style="width: 25px; height: 25px"
                      class="d-none d-md-block"
                    >
                      <CheckRoundedIcon />
                    </div>
                    <p
                      class="platform-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500"
                    >
                      {{ $t("trading platform.analysis-text") }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="platform-item d-flex flex-row justify-content-center">
                <div class="d-md-none">
                  <CheckRoundedIcon />
                </div>
                <div>
                  <h3>{{ $t("trading platform.calendar") }}</h3>
                  <div class="d-flex" style="gap: 12px">
                    <div
                      style="width: 25px; height: 25px"
                      class="d-none d-md-block"
                    >
                      <CheckRoundedIcon />
                    </div>
                    <p
                      class="platform-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500"
                    >
                      {{ $t("trading platform.caledar-text") }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="platform-item flex flex-row justify-content-center">
                <div class="d-md-none">
                  <CheckRoundedIcon />
                </div>
                <div>
                  <h3>{{ $t("trading platform.crypto") }}</h3>
                  <div class="d-flex" style="gap: 12px">
                    <div
                      style="width: 25px; height: 25px"
                      class="d-none d-md-block"
                    >
                      <CheckRoundedIcon />
                    </div>
                    <p
                      class="platform-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500"
                    >
                      {{ $t("trading platform.crypto-text") }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="platform-item flex flex-row justify-content-center">
                <div class="d-md-none">
                  <CheckRoundedIcon />
                </div>
                <div>
                  <h3>{{ $t("trading platform.trading") }}</h3>
                  <div class="d-flex" style="gap: 12px">
                    <div
                      style="width: 25px; height: 25px"
                      class="d-none d-md-block"
                    >
                      <CheckRoundedIcon />
                    </div>
                    <p
                      class="platform-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500"
                    >
                      {{ $t("trading platform.trading-text") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckRoundedIcon from "@/components/icons/CheckRoundedIcon.vue";
import Background from "../svg/why-choose-mt5/Background.vue";
import TabletBackground from "../svg/why-choose-mt5/TabletBackground.vue";

export default {
  components: {
    CheckRoundedIcon,
    Background,
    TabletBackground,
  },
};
</script>

<style lang="scss" scoped>
.platform {
  margin-top: 55px;
  @media (max-width: 991px) {
    margin-top: 120px;
  }
  @media (min-width: 992px) {
    margin-top: 160px;
  }
  .platform-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    /* padding: 48px 0 144px 0 !important; */
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 24px !important;
      padding-top: 0 !important;
      padding-bottom: 56px !important;
      margin-bottom: 16px !important;
    }
    @media screen and (max-width: 1199px) {
      grid-gap: 48px 32px !important;
      justify-content: center;
      max-width: 858px;
      margin: 56px auto 144px;
    }
    @media (min-width: 1200px) {
      max-width: 900px;
      margin: 56px auto 144px;
      grid-gap: 64px 72px !important;
    }
    .platform-item {
      background: #ffffff;
      box-shadow: 0px 8px 16px rgba(218, 225, 233, 0.56);
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      /* gap: 8px; */
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        width: 413px;
        height: 195px;
        padding: 17px 25px 0 25px;
      }
      @media screen and (max-width: 767px) {
        box-shadow: none;
        width: 340px;
        gap: 8px;
      }
      @media screen and (min-width: 1200px) {
        width: 413px;
        height: 181px;
        padding: 33px 25px 0 25px;
      }
      .platform-text {
        @media (max-width: 767px) {
          max-width: 250px;
        }

        @media (max-width: 1199px) and (min-width: 768px) {
          max-width: 347px;
        }
        @media (min-width: 1200px) {
          max-width: 347px;
        }
      }
    }
  }
}
h3 {
  color: #484c57 !important;
  margin-bottom: 8px !important;
  margin-top: 3px !important;
  @media (max-width: 767px) {
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
    line-height: 100%;
  }
  @media (min-width: 768px) {
    font-style: normal;
    font-weight: 500;
    font-size: 24px !important;
    line-height: 120%;
    margin: 0 auto 12px auto !important;
  }
}
p {
  margin: 0 !important;
}
h2 {
  @media (max-width: 767px) {
    margin-bottom: 40px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
  }
}
</style>
