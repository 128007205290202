var render = function () {
  var _vm$companySettings, _vm$companySettings2, _vm$companySettings3, _vm$companySettings4, _vm$companySettings5, _vm$companySettings6, _vm$companySettings7, _vm$companySettings8;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isCompanyFetched ? _c('div', {
    staticClass: "download section d-flex flex-column flex-md-row-reverse justify-content-center",
    staticStyle: {
      "gap": "43px"
    },
    attrs: {
      "id": "links"
    }
  }, [_c('div', {
    staticClass: "download-images"
  }, [_c('div', {
    staticClass: "d-md-none d-xl-none text-center"
  }, [_c('MobileDownloadIcon')], 1), _c('div', {
    staticClass: "d-none d-md-block"
  }, [_c('PCDownloadIcon', {
    attrs: {
      "id": "pc"
    }
  })], 1)]), _c('div', {
    staticClass: "download-content"
  }, [_c('h2', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("trading platform.download.title")) + " ")]), _c('h4', {
    staticClass: "subtitle-bodyLarge-to-mobile-body-regular text-grey-primary-main-500",
    staticStyle: {
      "max-width": "460px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trading platform.download.subtitle")) + " ")]), _c('div', {
    staticClass: "download-options"
  }, [_c('div', {
    staticClass: "download-option d-flex",
    staticStyle: {
      "gap": "24px"
    }
  }, [_c('a', {
    attrs: {
      "href": (_vm$companySettings = _vm.companySettings) === null || _vm$companySettings === void 0 ? void 0 : _vm$companySettings.platform_links.filter(function (p) {
        return p.platform_name.toLowerCase() == 'iOS'.toLowerCase();
      })[0].platform_link,
      "target": "_blank"
    }
  }, [_c('AppStore', {
    staticClass: "d-icons",
    staticStyle: {
      "width": "56px",
      "height": "56px"
    }
  })], 1), _c('div', {
    staticClass: "download-text"
  }, [_c('span', {
    staticClass: "small-text neutrals-4-text"
  }, [_vm._v(_vm._s(_vm.$t("trading platform.download.download from")))]), _c('h4', {
    staticClass: "subtitle-bodyLarge text-nowrap neutrals-2-text"
  }, [_c('a', {
    attrs: {
      "href": (_vm$companySettings2 = _vm.companySettings) === null || _vm$companySettings2 === void 0 ? void 0 : _vm$companySettings2.platform_links.filter(function (p) {
        return p.platform_name.toLowerCase() == 'iOS'.toLowerCase();
      })[0].platform_link,
      "target": "_blank"
    }
  }, [_vm._v(" AppStore ")])])])]), _c('hr'), _c('div', {
    staticClass: "download-option d-flex",
    staticStyle: {
      "gap": "24px"
    }
  }, [_c('a', {
    attrs: {
      "href": (_vm$companySettings3 = _vm.companySettings) === null || _vm$companySettings3 === void 0 ? void 0 : _vm$companySettings3.platform_links.filter(function (p) {
        return p.platform_name.toLowerCase() == 'macOS'.toLowerCase();
      })[0].platform_link,
      "target": "_blank"
    }
  }, [_c('AppleIcon', {
    staticClass: "d-icons"
  })], 1), _c('div', {
    staticClass: "download-text"
  }, [_c('span', {
    staticClass: "small-text neutrals-4-text"
  }, [_vm._v(_vm._s(_vm.$t("trading platform.download.download for")))]), _c('h4', {
    staticClass: "subtitle-bodyLarge text-nowrap neutrals-2-text"
  }, [_c('a', {
    attrs: {
      "href": (_vm$companySettings4 = _vm.companySettings) === null || _vm$companySettings4 === void 0 ? void 0 : _vm$companySettings4.platform_links.filter(function (p) {
        return p.platform_name.toLowerCase() == 'macOS'.toLowerCase();
      })[0].platform_link,
      "target": "_blank"
    }
  }, [_vm._v(" Mac OS ")])])])]), _c('hr'), _c('div', {
    staticClass: "download-option d-flex",
    staticStyle: {
      "gap": "24px"
    }
  }, [_c('a', {
    attrs: {
      "href": (_vm$companySettings5 = _vm.companySettings) === null || _vm$companySettings5 === void 0 ? void 0 : _vm$companySettings5.platform_links.filter(function (p) {
        return p.platform_name.toLowerCase() == 'Android'.toLowerCase();
      })[0].platform_link,
      "target": "_blank"
    }
  }, [_c('WindowIcon', {
    staticClass: "d-icons"
  })], 1), _c('div', {
    staticClass: "download-text"
  }, [_c('span', {
    staticClass: "small-text neutrals-4-text"
  }, [_vm._v(_vm._s(_vm.$t("trading platform.download.download from")))]), _c('h4', {
    staticClass: "subtitle-bodyLarge text-nowrap neutrals-2-text"
  }, [_c('a', {
    attrs: {
      "href": (_vm$companySettings6 = _vm.companySettings) === null || _vm$companySettings6 === void 0 ? void 0 : _vm$companySettings6.platform_links.filter(function (p) {
        return p.platform_name.toLowerCase() == 'Android'.toLowerCase();
      })[0].platform_link,
      "target": "_blank"
    }
  }, [_vm._v(" Google Play ")])])])]), _c('hr'), _c('div', {
    staticClass: "download-option d-flex",
    staticStyle: {
      "gap": "24px"
    }
  }, [_c('a', {
    attrs: {
      "href": (_vm$companySettings7 = _vm.companySettings) === null || _vm$companySettings7 === void 0 ? void 0 : _vm$companySettings7.platform_links.filter(function (p) {
        return p.platform_name.toLowerCase() == 'Windows'.toLowerCase();
      })[0].platform_link,
      "target": "_blank"
    }
  }, [_c('GooglePlayIcon', {
    staticClass: "d-icons"
  })], 1), _c('div', {
    staticClass: "download-text"
  }, [_c('span', {
    staticClass: "small-text neutrals-4-text"
  }, [_vm._v(_vm._s(_vm.$t("trading platform.download.download for")))]), _c('h4', {
    staticClass: "subtitle-bodyLarge text-nowrap neutrals-2-text"
  }, [_c('a', {
    attrs: {
      "href": (_vm$companySettings8 = _vm.companySettings) === null || _vm$companySettings8 === void 0 ? void 0 : _vm$companySettings8.platform_links.filter(function (p) {
        return p.platform_name.toLowerCase() == 'Windows'.toLowerCase();
      })[0].platform_link,
      "target": "_blank"
    }
  }, [_vm._v(" Windows ")])])])])])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }