var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "366",
      "height": "210",
      "viewBox": "0 0 366 210",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M286.179 49.8161H200.855C200.855 38.8472 208.107 29.5179 218.205 26.1051C220.729 14.8155 231.084 6.35938 243.476 6.35938C255.85 6.35938 266.195 14.7977 268.729 26.0695C278.881 29.4555 286.179 38.8115 286.179 49.8161Z",
      "fill": "#ED6400"
    }
  }), _c('g', {
    staticStyle: {
      "mix-blend-mode": "multiply"
    }
  }, [_c('rect', {
    attrs: {
      "x": "312.688",
      "y": "13.2461",
      "width": "45.0466",
      "height": "66.7749",
      "fill": "#07B53B"
    }
  })]), _c('rect', {
    attrs: {
      "x": "316.043",
      "y": "10.7673",
      "width": "43.6486",
      "height": "65.377",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.39793"
    }
  }), _c('circle', {
    attrs: {
      "cx": "338.117",
      "cy": "66.7716",
      "r": "3.17976",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "280.882",
      "cy": "15.368",
      "r": "4.83362",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.931956"
    }
  }), _c('path', {
    attrs: {
      "d": "M298.438 59.7344L294.503 63.6691",
      "stroke": "#07B53B",
      "stroke-width": "0.931956",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M294.516 59.7344L298.45 63.6691",
      "stroke": "#07B53B",
      "stroke-width": "0.931956",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M304.199 86.3789H365.145",
      "stroke": "#07B53B",
      "stroke-width": "0.931956",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M306.246 83.2012H334.469",
      "stroke": "#07B53B",
      "stroke-width": "0.931956",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M327.781 18.0176H346.595",
      "stroke": "#ED6400",
      "stroke-width": "1.39793"
    }
  }), _c('circle', {
    attrs: {
      "cx": "209.863",
      "cy": "3.17976",
      "r": "2.71378",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.931956"
    }
  }), _c('circle', {
    attrs: {
      "cx": "294.129",
      "cy": "154.217",
      "r": "2.71378",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.931956"
    }
  }), _c('circle', {
    attrs: {
      "cx": "166.673",
      "cy": "12.9831",
      "r": "2.4488",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.931956"
    }
  }), _c('circle', {
    attrs: {
      "cx": "171.178",
      "cy": "50.3445",
      "r": "4.83362",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.931956"
    }
  }), _c('path', {
    attrs: {
      "d": "M187.148 12.0391L183.214 15.9738",
      "stroke": "#07B53B",
      "stroke-width": "0.931956",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M183.227 12.0391L187.161 15.9738",
      "stroke": "#07B53B",
      "stroke-width": "0.931956",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M343.48 162.02L339.546 165.954",
      "stroke": "#07B53B",
      "stroke-width": "0.931956",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M339.559 162.02L343.493 165.954",
      "stroke": "#07B53B",
      "stroke-width": "0.931956",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "292.955",
      "cy": "119.124",
      "r": "20.0902",
      "stroke": "#ED6400",
      "stroke-width": "0.931956"
    }
  }), _c('path', {
    attrs: {
      "d": "M306.309 134.455L315.573 143.719",
      "stroke": "#ED6400",
      "stroke-width": "0.931956",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M313.48 141.627L322.745 150.891",
      "stroke": "#ED6400",
      "stroke-width": "4.65978",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M284.383 119.375L290.258 125.25L302.008 113.5",
      "stroke": "#07B53B",
      "stroke-width": "1.39793",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M231.061 29.9432C170.911 27.0283 133.178 50.9826 156.072 72.605C184.69 99.6329 234.506 69.1602 251.73 96.4531C268.953 123.746 283.792 196.615 316.12 173.562C348.447 150.509 300.221 60.1509 332.019 49.8167",
      "stroke": "#07B53B",
      "stroke-width": "2.32989",
      "stroke-linecap": "round",
      "stroke-dasharray": "4.66 4.66"
    }
  }), _c('rect', {
    attrs: {
      "x": "84.8008",
      "y": "77.9062",
      "width": "170.117",
      "height": "102.282",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "77.375",
      "y": "180.189",
      "width": "184.956",
      "height": "14.8389",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "134.319",
      "y": "180.422",
      "width": "66.8389",
      "height": "2.71378",
      "stroke": "#07B53B",
      "stroke-width": "0.465978"
    }
  }), _c('path', {
    attrs: {
      "d": "M80.5536 163.756H12.7188C12.7188 155.061 18.4842 147.666 26.512 144.961C28.5189 136.012 36.751 129.309 46.6033 129.309C56.441 129.309 64.6658 135.997 66.6801 144.932C74.7517 147.616 80.5536 155.033 80.5536 163.756Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M51.936 156.338H0C0 149.649 4.41415 143.961 10.5604 141.88C12.097 134.996 18.3997 129.84 25.9429 129.84C33.4749 129.84 39.772 134.985 41.3142 141.858C47.4939 143.923 51.936 149.628 51.936 156.338Z",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "101.753",
      "cy": "60.4148",
      "r": "4.83362",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.931956"
    }
  }), _c('circle', {
    attrs: {
      "cx": "62.2663",
      "cy": "114.206",
      "r": "2.4488",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "0.931956"
    }
  }), _c('path', {
    attrs: {
      "d": "M71.0195 208.801H149.984",
      "stroke": "#07B53B",
      "stroke-width": "1.39793",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M73.6758 202.971H110.243",
      "stroke": "#07B53B",
      "stroke-width": "1.39793",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M120.309 202.971L207.752 202.971",
      "stroke": "#07B53B",
      "stroke-width": "1.39793",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "95.574",
      "y": "74.8943",
      "width": "168.719",
      "height": "100.884",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.39793"
    }
  }), _c('rect', {
    attrs: {
      "x": "88.1482",
      "y": "175.59",
      "width": "183.558",
      "height": "13.4409",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.39793"
    }
  }), _c('rect', {
    attrs: {
      "x": "144.16",
      "y": "175.949",
      "width": "67.3049",
      "height": "3.17976",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "128.781",
      "y": "109.172",
      "width": "102.282",
      "height": "7.94939",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "128.781",
      "y": "124.539",
      "width": "60.4154",
      "height": "7.94939",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "128.781",
      "y": "139.91",
      "width": "44.5166",
      "height": "7.94939",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }