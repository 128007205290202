var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "550",
      "height": "705",
      "viewBox": "0 0 550 705",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M86 258H32C32 250.933 36.5896 244.921 42.9801 242.723C44.5777 235.448 51.1309 230 58.9739 230C66.8052 230 73.3526 235.437 74.956 242.7C81.3814 244.881 86 250.91 86 258Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M539 574H441C441 561.38 449.329 550.645 460.927 546.719C463.826 533.729 475.719 524 489.953 524C504.165 524 516.047 533.709 518.957 546.678C530.618 550.574 539 561.339 539 574Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M544 563H490C490 555.933 494.59 549.921 500.98 547.723C502.578 540.448 509.131 535 516.974 535C524.805 535 531.353 540.437 532.956 547.7C539.381 549.881 544 555.91 544 563Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M74.8945 171.637L64.3607 182.171",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M64.3633 171.637L74.8971 182.171",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M70.6367 621.387L60.1029 631.921",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M60.1055 621.387L70.6393 631.921",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M543.801 187.266L533.267 197.799",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M533.27 187.266L543.803 197.799",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M15.8008 506.266L5.26694 516.799",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M5.26953 506.266L15.8034 516.799",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "51.1877",
      "cy": "320.188",
      "r": "13.1877",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('circle', {
    attrs: {
      "r": "8",
      "transform": "matrix(-1 0 0 1 73 377)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('circle', {
    attrs: {
      "r": "8",
      "transform": "matrix(-1 0 0 1 528 448)",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('circle', {
    attrs: {
      "cx": "526.581",
      "cy": "259.301",
      "r": "6.80324",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M244.906 661.523H466.678",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M127.195 671.453H261.05",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M271.91 671.453H320.148",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M398.18 671.453H413.786",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M460.605 671.453H491.818",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M334.336 671.453H349.942",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M481.526 379.896V599.548C481.526 620.599 465.039 637.645 444.68 637.645H162.623C142.264 637.645 125.777 620.599 125.777 599.548V92.4687C125.777 71.4175 142.264 54.3711 162.623 54.3711H444.68C465.039 54.3711 481.526 71.4175 481.526 92.4687V200.908",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M465.916 389.743V609.395C465.916 630.446 449.43 647.493 429.07 647.493H147.014C126.654 647.493 110.168 630.446 110.168 609.395V102.316C110.168 81.2651 126.654 64.2188 147.014 64.2188H429.07C449.43 64.2188 465.916 81.2651 465.916 102.316V210.756",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M465.916 389.743V609.395C465.916 630.446 449.43 647.493 429.07 647.493H147.014C126.654 647.493 110.168 630.446 110.168 609.395V102.316C110.168 81.2651 126.654 64.2188 147.014 64.2188H429.07C449.43 64.2188 465.916 81.2651 465.916 102.316V210.756",
      "stroke": "#ED6400",
      "stroke-width": "4",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M235.391 303.125L250 344.375L264.531 303.125H279.922V360H268.164V344.453L269.336 317.617L253.984 360H245.938L230.625 317.656L231.797 344.453V360H220.078V303.125H235.391ZM330.997 312.617H313.575V360H301.856V312.617H284.669V303.125H330.997V312.617ZM337.384 332.031L340.666 303.125H372.541V312.539H349.923L348.517 324.766C351.199 323.333 354.051 322.617 357.072 322.617C362.489 322.617 366.733 324.297 369.806 327.656C372.879 331.016 374.416 335.716 374.416 341.758C374.416 345.43 373.634 348.724 372.072 351.641C370.535 354.531 368.322 356.784 365.431 358.398C362.541 359.987 359.129 360.781 355.197 360.781C351.759 360.781 348.569 360.091 345.627 358.711C342.684 357.305 340.353 355.339 338.634 352.812C336.942 350.286 336.043 347.409 335.939 344.18H347.111C347.345 346.549 348.166 348.398 349.572 349.727C351.004 351.029 352.866 351.68 355.158 351.68C357.71 351.68 359.676 350.768 361.056 348.945C362.436 347.096 363.127 344.492 363.127 341.133C363.127 337.904 362.332 335.43 360.744 333.711C359.155 331.992 356.903 331.133 353.986 331.133C351.304 331.133 349.129 331.836 347.463 333.242L346.369 334.258L337.384 332.031Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M303.653 606.428C314.02 606.428 322.424 597.738 322.424 587.02C322.424 576.301 314.02 567.611 303.653 567.611C293.287 567.611 284.883 576.301 284.883 587.02C284.883 597.738 293.287 606.428 303.653 606.428Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M472.188 2V41.644",
      "stroke": "#ED6400",
      "stroke-width": "4",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M541.344 79.8242H502.656",
      "stroke": "#ED6400",
      "stroke-width": "4",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M527.004 20.3184L488.316 59.9624",
      "stroke": "#ED6400",
      "stroke-width": "4",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M262.047 95.8945H356.79",
      "stroke": "#ED6400",
      "stroke-width": "5",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M485.5 260C516.333 276 547.407 308.301 515.5 373C443.499 519 59.4994 333.558 59.5 487.5C59.5007 686 333.5 619.5 334.5 686",
      "stroke": "#07B53B",
      "stroke-width": "6",
      "stroke-linecap": "round",
      "stroke-dasharray": "10 10"
    }
  }), _c('path', {
    attrs: {
      "d": "M105 268H51C51 260.933 55.5896 254.921 61.9801 252.723C63.5777 245.448 70.1309 240 77.9739 240C85.8052 240 92.3526 245.437 93.956 252.7C100.381 254.881 105 260.91 105 268Z",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "55",
      "cy": "549",
      "r": "27",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "336",
      "cy": "666",
      "r": "39",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }