var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "56",
      "height": "56",
      "rx": "28",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M26.3078 22.5538C25.7128 22.2872 25.0718 22 24 22C20 22 19 26.0002 19 30C19 33.9998 22 39 24 39C25.1716 39 26 38.6569 26.6863 38.3726C27.1716 38.1716 27.5858 38 28 38C28.4142 38 28.8284 38.1716 29.3137 38.3726C30 38.6569 30.8284 39 32 39C33.4277 39 35.3651 36.4521 36.3569 33.5387C36.4567 33.2453 36.253 32.9427 35.9538 32.8618C34.2521 32.4019 33 30.8472 33 29C33 27.257 34.1149 25.7743 35.6705 25.2263C35.9659 25.1222 36.1482 24.7987 36.009 24.5181C35.26 23.008 34.008 22 32 22C30.9282 22 30.2872 22.2872 29.6922 22.5538C29.1769 22.7846 28.6962 23 28 23C27.3038 23 26.8231 22.7846 26.3078 22.5538Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M28 20.75C28 18.6789 29.6789 17 31.75 17C31.8881 17 32 17.1119 32 17.25C32 19.3211 30.3211 21 28.25 21C28.1119 21 28 20.8881 28 20.75Z",
      "fill": "white"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }